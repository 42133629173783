import React, {Component} from 'react';
import {Button, Container, Dimmer, Header, Image, Segment} from 'semantic-ui-react';
import promo from "./assets/promo3.gif";
import styles from "./promo.module.css";
import {Link} from "@reach/router";
import DesktopContainer from "../../container/DesktopContainer";
import MobileBgWrapper from "../../container/MobileBgWrapper";
import ShowVideoButton from "../utils/buttons/ShowVideoButton";
import MobileTabletContainer from "../../container/MobileTabletContainer";

class PromoVideo extends Component {
    state = {};

    handleShow = () => this.setState({active: true});
    handleHide = () => this.setState({active: false});

    render() {
        const {active} = this.state;
        const content = (
            <Link to={'/video/'}><Button className={'secondary'}>Zum Video</Button></Link>
        );

        return (
            <div>
                <MobileTabletContainer className={styles.mobile}>
                    <MobileBgWrapper>
                        <Container>
                            <Segment className={styles.main} raised>
                                <Image fluid src={promo} />
                                <div className={styles.buttonContainer}>
                                    <div className={styles.button}>
                                    <ShowVideoButton link={'/video/'}>Zum Video</ShowVideoButton>
                                    </div>
                                </div>
                            </Segment>
                        </Container>
                    </MobileBgWrapper>
                </MobileTabletContainer>
                <DesktopContainer className={styles.desktop}>
                    <Container fluid className={styles.container}>
                    <Segment className={styles.main} raised>
                        <Dimmer.Dimmable as={Image}
                                         blurring
                                         dimmed={active}
                                         dimmer={{active, content}}
                                         onMouseEnter={this.handleShow}
                                         onMouseLeave={this.handleHide}
                                         src={promo}
                                         fluid
                        />
                        <Header as={'h3'} className={styles.header}>
                            Hilfe, wo sie notwendig ist
                        </Header>
                        <p>Hautkranke Kinder und Jugendliche benötigen ebenso eine spezielle gesundheitliche
                            Versorgung in Kliniken und Praxen, wie sie längst für Erwachsene besteht.</p>
                        <p>Die Deutsche Stiftung Kinderdermatologie hat sich zum Ziel gesetzt, dort zu
                            unterstützen, wo Hilfe für hautkranke Kinder und Jugendliche dringend benötigt
                            wird.</p>
                        <Button basic color='red' as='a' size='small' href={'/video/'}>
                            Zum Video
                        </Button>
                    </Segment>
                    </Container>
                </DesktopContainer>
            </div>
        );
    }
}


export default PromoVideo;