import React, {Component} from 'react';
import styles from "./slider.module.css";
import {Button, Container, Icon} from "semantic-ui-react";
import Carousel from 'nuka-carousel';
import {graphql, StaticQuery} from "gatsby";
import TabletContainer from "../../container/TabletContainer";
import DesktopContainer from "../../container/DesktopContainer";
import MobileContainer from "../../container/MobileContainer";

class LogoCarousel extends Component {
    render() {
        return (
            <StaticQuery query={logoCarouselQuery}
                         render={
                             data => {
                                 const DesktopImages = data.markdownRemark.frontmatter.images.map(i =>
                                     <>
                                         <a href={i.link} target="_blank">
                                             <img alt="" className={styles.image}
                                                  src={i.desktopImg.childImageSharp.fixed.src}/>
                                         </a>
                                     </>
                                 );
                                 const TabletImages = data.markdownRemark.frontmatter.images.map(i =>
                                     <>
                                         <a href={i.link} target="_blank">
                                             <img alt="" className={styles.image} src={i.tabletImg.childImageSharp.fixed.src}/>
                                         </a>
                                     </>

                                 );
                                 const MobileImages = data.markdownRemark.frontmatter.images.map(i =>
                                     <>
                                         <a href={i.link} target="_blank">
                                             <img alt="" className={styles.image} src={i.mobileImg.childImageSharp.fixed.src}/>
                                         </a>
                                     </>
                                 );
                                 return (<>
                                     <MobileContainer className={styles.mobile}>
                                         <div className={styles.logoCarousel}>
                                             <Carousel
                                                 className={styles.carousel}
                                                 initialSlideHeight={50}
                                                 heightMode={"first"}
                                                 autoplay={true}
                                                 wrapAround={true}
                                                 transitionMode={"scroll"}
                                                 autoplayInterval={1500}
                                                 speed={1000}
                                                 renderCenterLeftControls={({previousSlide}) => (
                                                     <Button icon onClick={previousSlide} className={styles.button}>
                                                         <Icon name={'chevron left'} size={'big'}/>
                                                     </Button>
                                                 )}
                                                 renderCenterRightControls={({nextSlide}) => (
                                                     <Button icon onClick={nextSlide} className={styles.button}>
                                                         <Icon name={'chevron right'} size={'big'}/>
                                                     </Button>
                                                 )}
                                             >
                                                 {MobileImages}
                                             </Carousel>
                                         </div>
                                     </MobileContainer>
                                     <TabletContainer className={styles.tablet}>
                                         <Container className={styles.logoCarousel}>
                                             <Carousel
                                                 className={styles.carousel}
                                                 initialSlideHeight={50}
                                                 heightMode={"first"}
                                                 autoplay={true}
                                                 wrapAround={true}
                                                 transitionMode={"scroll"}
                                                 autoplayInterval={1500}
                                                 speed={1000}
                                                 renderCenterLeftControls={({previousSlide}) => (
                                                     <Button icon onClick={previousSlide} className={styles.button}>
                                                         <Icon name={'chevron left'} size={'big'}/>
                                                     </Button>
                                                 )}
                                                 renderCenterRightControls={({nextSlide}) => (
                                                     <Button icon onClick={nextSlide} className={styles.button}>
                                                         <Icon name={'chevron right'} size={'big'}/>
                                                     </Button>
                                                 )}
                                             >
                                                 {TabletImages}
                                             </Carousel>
                                         </Container>
                                     </TabletContainer>
                                     <DesktopContainer className={styles.desktop}>
                                         <Container className={styles.logoCarousel}>
                                             <Carousel
                                                 className={styles.carousel}
                                                 initialSlideHeight={100}
                                                 autoplay={true}
                                                 wrapAround={true}
                                                 transitionMode={"scroll"}
                                                 autoplayInterval={1500}
                                                 speed={1000}
                                                 renderCenterLeftControls={({previousSlide}) => (
                                                     <Button icon onClick={previousSlide} className={styles.button}>
                                                         <Icon name={'chevron left'} size={'big'}/>
                                                     </Button>
                                                 )}
                                                 renderCenterRightControls={({nextSlide}) => (
                                                     <Button icon onClick={nextSlide} className={styles.button}>
                                                         <Icon name={'chevron right'} size={'big'}/>
                                                     </Button>
                                                 )}
                                             >
                                                 {DesktopImages}
                                             </Carousel>
                                         </Container>
                                     </DesktopContainer>
                                 </>)
                             }
                         }
            />
        );
    }
}

export default LogoCarousel;

const logoCarouselQuery = graphql`
query logoCarouselQuery {
      markdownRemark(frontmatter: {type: {eq: "image-gallery-logos"}, path: {eq:"/"}}) {
        frontmatter {
          images {
            link
            desktopImg: image {
              childImageSharp {
                fixed(height: 250) {
                  src
                }
              }
            }
            tabletImg: image {
              childImageSharp {
                fixed(height: 150) {
                  src
                }
              }
            }
            mobileImg: image {
              childImageSharp {
                fixed(width: 320) {
                  src
                }
              }
            }
          }
        }
      }
    }

`;




