import React from 'react';
import {Container} from 'semantic-ui-react';
import styles from "./recent.module.css";
import DesktopContainer from "../../container/DesktopContainer";
import MobileBgWrapper from "../../container/MobileBgWrapper";
import ButtonNav from "../utils/buttons/ButtonNav";
import RecentArticles from "./RecentArticles";
import MobileTabletContainer from "../../container/MobileTabletContainer";


const Recent = () => (
    <div>
        <MobileTabletContainer className={styles.mobile}>
            <MobileBgWrapper>
            <Container fluid className={styles.recent}>
                <RecentArticles/>
                <ButtonNav link={'/news/'}>Zu den News</ButtonNav>
            </Container>
            </MobileBgWrapper>
        </MobileTabletContainer>
        <DesktopContainer className={styles.desktop}>
            <Container className={styles.recent}>
                <RecentArticles/>
            </Container>
        </DesktopContainer>
    </div>
);

export default Recent;