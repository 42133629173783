import React, {Component} from 'react';
import {Card, Container, Icon,} from 'semantic-ui-react';
import styles from "./preview.module.css";
import DesktopContainer from "../../container/DesktopContainer";
import {graphql, StaticQuery} from "gatsby";
import MobileBgWrapper from "../../container/MobileBgWrapper";
import ButtonNav from "../utils/buttons/ButtonNav";
import MobileTabletContainer from "../../container/MobileTabletContainer";
import Img from "gatsby-image";


class ContactPreview extends Component {
    render() {
        return (
            <StaticQuery query={contact1Query}
                         render={data => (
                             <div>
                                 <MobileTabletContainer className={styles.mobile}>
                                     <MobileBgWrapper>
                                         <div className={styles.header}>
                                             <h2><Icon name={'address card'} color={'violet'}/>Kontakt</h2>
                                         </div>
                                         <Container fluid>
                                             <div className={styles.avatars}>
                                                 <Img className={styles.image}
                                                      fluid={data.imageTwo.childImageSharp.fluid}/>
                                                 <Img className={styles.image}
                                                      fluid={data.imageOne.childImageSharp.fluid}/>
                                             </div>
                                             <Card className={styles.card}>
                                                 <Card.Content>
                                                         <Card.Header className={styles.header}>
                                                             Bei Fragen sind wir gerne für Sie da!
                                                         </Card.Header>
                                                     <Card.Meta className={styles.meta}>
                                                         Michaela Gerathewohl & <br/> Josefine Kitzinger
                                                     </Card.Meta>
                                                     <Card.Description>
                                                         <p><span
                                                             className={styles.label}>Telefon:</span> {data.markdownRemark.frontmatter.phone}
                                                         </p>
                                                         <p><span
                                                             className={styles.label}>Fax:</span> {data.markdownRemark.frontmatter.fax}
                                                         </p>
                                                         <p>{data.markdownRemark.frontmatter.street}</p>
                                                         <p>{data.markdownRemark.frontmatter.zip} {data.markdownRemark.frontmatter.place}</p>
                                                         <p><a
                                                             href={`mailto:${data.markdownRemark.frontmatter.mail}`}><Icon
                                                             link name={'mail'} color={'blue'}/></a><span
                                                             className={styles.label}>E-Mail</span></p>
                                                     </Card.Description>
                                                 </Card.Content>
                                             </Card>
                                         </Container>

                                         <Container fluid>
                                             <ButtonNav link={'/kontakt/'}>Kontaktieren</ButtonNav>
                                         </Container>
                                     </MobileBgWrapper>
                                 </MobileTabletContainer>
                                 <DesktopContainer className={styles.desktop}>
                                     <div className={styles.avatars}>
                                     <Img className={styles.image} style={{marginLeft: "0"}}
                                          fluid={data.imageTwo.childImageSharp.fluid}/>
                                     <Img className={styles.image}
                                          fluid={data.imageOne.childImageSharp.fluid}/>
                                     </div>
                                     <Card className={styles.card}>
                                         <Card.Content>
                                             <Card.Header className={styles.header}>
                                                 Bei Fragen sind wir gerne für Sie da!
                                             </Card.Header>
                                             <Card.Meta className={styles.meta}>
                                                 Michaela Gerathewohl & <br/> Josefine Kitzinger
                                             </Card.Meta>
                                             <Card.Description className={styles.descr}>
                                                 <p><span
                                                     className={styles.label}>Telefon:</span> {data.markdownRemark.frontmatter.phone}
                                                 </p>
                                                 <p><span
                                                     className={styles.label}>Fax:</span> {data.markdownRemark.frontmatter.fax}
                                                 </p>
                                                 <p>{data.markdownRemark.frontmatter.street}</p>
                                                 <p>{data.markdownRemark.frontmatter.zip} {data.markdownRemark.frontmatter.place}</p>
                                                 <p><a href={`mailto:${data.markdownRemark.frontmatter.mail}`}><Icon
                                                     link name={'mail'} color={'blue'}/></a><span
                                                     className={styles.label}>E-Mail</span></p>
                                             </Card.Description>
                                         </Card.Content>
                                         {!!data.markdownRemark.frontmatter.donation_phone &&
                                         <Card.Content extra className={styles.descr}>
                                             <p><span className={styles.label}>24h Spendenservicetelefon:</span>
                                                 <br/>{data.markdownRemark.frontmatter.donation_phone}*
                                             </p>
                                             <p className={styles.remark}>*{data.markdownRemark.frontmatter.donation_phone_remark}</p>
                                         </Card.Content>
                                         }
                                     </Card>
                                 </DesktopContainer>
                             </div>
                         )}
            />
        );
    }
}


export default ContactPreview;

export const contact1Query = graphql`
query contact1Query {
    imageOne: file(relativePath: { eq: "ker_sq.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 500) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    imageTwo: file(relativePath: { eq: "gerhl_sq.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 500) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    markdownRemark(frontmatter: { type: { eq: "contact" }}) {
        frontmatter {
            title
            name
            phone
            mail
            zip
            place
            street
            image{
                childImageSharp {
                    fixed(width: 150) {
                        ...GatsbyImageSharpFixed_noBase64
                    }
                }
            }
            fax
            donation_phone
            donation_phone_remark
        }
    }
}
`;