import React from 'react';
import {Container, Header, Item, Segment} from 'semantic-ui-react';
import styles from "./recent.module.css";
import {graphql, StaticQuery} from "gatsby";
import ButtonNav from "../utils/buttons/ButtonNav";
import DesktopContainer from "../../container/DesktopContainer";
import MobileTabletContainer from "../../container/MobileTabletContainer";
import DefaultBasicButton from "../utils/buttons/DefaultBasicButton";
import Img from "../utils/Img";

const RecentArticles = () => (
    <StaticQuery query={recentQuery}
                 render={
                     data => {
                         const Articles = data.allMarkdownRemark.edges.map(i =>
                             <div key={i.id}>
                                 <MobileTabletContainer>
                                     <>
                                         <Segment className={styles.wrapper} raised>
                                             {
                                                 !!i.node.frontmatter.image &&
                                                 <Img fluid={i.node.frontmatter.image.childImageSharp.fluid}/>
                                             }
                                             <Header as='h2' className={styles.header}>
                                                 {i.node.frontmatter.title}
                                             </Header>
                                             <Container fluid style={{marginBottom: '1rem'}} className={styles.content}
                                                        dangerouslySetInnerHTML={{__html: i.node.html}}>
                                             </Container>
                                             {
                                                 !!i.node.frontmatter.link &&
                                                 <DefaultBasicButton link={i.node.frontmatter.link}>Mehr
                                                     erfahren</DefaultBasicButton>
                                             }
                                         </Segment>
                                     </>
                                 </MobileTabletContainer>
                                 <DesktopContainer>
                                     <Segment className={styles.wrapper} raised>
                                         <Item.Group>
                                             <Item>
                                                 <Item.Image>
                                                     {
                                                         !!i.node.frontmatter.image &&
                                                         <Img fluid={i.node.frontmatter.image.childImageSharp.fluid}/>
                                                     }
                                                 </Item.Image>
                                                 <Item.Content>
                                                     <Item.Header
                                                         className={styles.header}>{i.node.frontmatter.title}</Item.Header>
                                                     <Item.Description>
                                                         <Container style={{marginBottom: '1rem'}}
                                                                    className={styles.content}
                                                                    dangerouslySetInnerHTML={{__html: i.node.html}}>
                                                         </Container>
                                                     </Item.Description>
                                                     <Item.Extra>
                                                         {
                                                             !!i.node.frontmatter.link &&
                                                             <ButtonNav link={i.node.frontmatter.link}>Mehr
                                                                 erfahren</ButtonNav>
                                                         }
                                                     </Item.Extra>
                                                 </Item.Content>
                                             </Item>
                                         </Item.Group>
                                     </Segment>
                                 </DesktopContainer>
                             </div>
                         );
                         return (<>{Articles}</>)
                     }
                 }
    />
);

export default RecentArticles;

const recentQuery = graphql`
query recentQuery {
    allMarkdownRemark( 
      filter:{
        frontmatter: {type: { eq: "recent" }}
      }
      sort: { order: DESC, fields: [frontmatter___date] }
      ){
      edges {
        node {
        id
            html
            frontmatter {
                title
                link
                image {
                  childImageSharp {
                    fluid(maxHeight: 800) {
                      ...GatsbyImageSharpFluid_noBase64
                    }
                  }
                }
            }
        }
      }
    }
}
`;