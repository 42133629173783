import React, {Component} from 'react';
import {Card, Container, Header, Icon,} from 'semantic-ui-react';
import styles from "./preview.module.css";
import DesktopContainer from "../../container/DesktopContainer";
import {graphql, StaticQuery} from "gatsby";
import MobileBgWrapper from "../../container/MobileBgWrapper";
import MobileTabletContainer from "../../container/MobileTabletContainer";
import Img from "../utils/Img";

class SupporterPreview extends Component {
    render() {
        return (
            <StaticQuery query={supporterImgQuery}
                         render={data => (
                             <div className={styles.container}>
                                 <MobileTabletContainer className={styles.mobile}>
                                     <MobileBgWrapper>
                                         <div className={styles.header}>
                                             <h2><Icon name={'user md'} color={'violet'}/>Unterstützer</h2>
                                         </div>
                                         <Container>

                                             <div className={styles.main}>
                                                 <Card className={styles.item}>
                                                     <a href={'https://www.derma.de/'}>
                                                         <Img className={styles.image}
                                                              fluid={data.imageOne.childImageSharp.fluid}/>
                                                     </a>
                                                 </Card>
                                                 <Card className={styles.card}>
                                                     <a href={'https://www.bvdd.de/de/'}>
                                                         <Img className={styles.image}
                                                              fluid={data.imageTwo.childImageSharp.fluid}/>
                                                     </a>
                                                 </Card>
                                                 <Card className={styles.card}>
                                                     <a href={'https://www.hautnet.de/'}>
                                                         <Img className={styles.image}
                                                              fluid={data.imageThree.childImageSharp.fluid}/>
                                                     </a>
                                                 </Card>
                                                 <Card className={styles.card}>
                                                     <a href={'http://www.paediatrische-dermatologie.de/'}>
                                                         <Img className={styles.image}
                                                              fluid={data.imageFour.childImageSharp.fluid}/>
                                                     </a>
                                                 </Card>
                                             </div>
                                             {/*<ButtonNav link={'/sponsoren/'}>Sponsoren ansehen</ButtonNav>*/}
                                         </Container>
                                     </MobileBgWrapper>
                                 </MobileTabletContainer>
                                 <DesktopContainer className={styles.desktop}>
                                     <div className={styles.main}>
                                         <Header as='h4' className={styles.header}>Mit Unterstützung von:</Header>
                                         <>
                                             <Card className={styles.card}>
                                                 <a href={'https://www.derma.de/'}>
                                                     <Img className={styles.image}
                                                          fluid={data.imageOne.childImageSharp.fluid}/>
                                                 </a>
                                             </Card>
                                             <Card className={styles.card}>
                                                 <a href={'https://www.bvdd.de/de/'}>
                                                     <Img className={styles.image}
                                                          fluid={data.imageTwo.childImageSharp.fluid}/>
                                                 </a>
                                             </Card>
                                             <Card className={styles.card}>
                                                 <a href={'https://www.hautnet.de/'}>
                                                     <Img className={styles.image}
                                                          fluid={data.imageThree.childImageSharp.fluid}/>
                                                 </a>
                                             </Card>
                                             <Card className={styles.card}>
                                                 <a href={'http://www.paediatrische-dermatologie.de/'}>
                                                     <Img className={styles.image}
                                                          fluid={data.imageFour.childImageSharp.fluid}/>
                                                 </a>
                                             </Card>
                                         </>

                                         {/*<div className={styles.button}>*/}
                                             {/*<ButtonNav link={'/sponsoren/'}>Sponsoren & Förderer</ButtonNav>*/}
                                         {/*</div>*/}
                                     </div>
                                 </DesktopContainer>
                             </div>
                         )}
            />

        );
    }
}


export default SupporterPreview;

const supporterImgQuery = graphql`
  query supporterImgQuery {
    imageOne: file(relativePath: { eq: "ddg4.png" }) {
      childImageSharp {
        fluid(maxWidth: 500) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    imageTwo: file(relativePath: { eq: "bvdd_logo.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 500) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    imageThree: file(relativePath: { eq: "nipd3.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 500) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    imageFour: file(relativePath: { eq: "agp.png" }) {
      childImageSharp {
        fluid(maxWidth: 500) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
  }
`;