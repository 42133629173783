import React, {Component} from 'react';
import {Container, Header, Segment,} from 'semantic-ui-react';
import styles from "./announcement.module.css";
import DesktopContainer from "../../container/DesktopContainer";
import {graphql, StaticQuery} from "gatsby";
import PrimaryButton from "../utils/buttons/PrimaryButton";
import MobileTabletContainer from "../../container/MobileTabletContainer";
import NotebookContainer from "../../container/NotebookContainer";
import WideScreenContainer from "../../container/WideScreenContainer";
import cx from 'classnames';

class Announcement extends Component {

    render() {
        return (
                <StaticQuery query={announcementQuery}
                             render={data => (
                                 <>
                                     {!!data.markdownRemark &&
                                     <>
                                         <MobileTabletContainer className={styles.mobile}>
                                             <Container style={{marginBottom: '2em'}}>
                                                 <Segment className={styles.container} raised>
                                                     <Header as={'h1'} textAlign={'center'}
                                                             style={{marginBottom: '1.33rem'}}>
                                                         {data.markdownRemark.frontmatter.title}
                                                     </Header>
                                                     <Container style={{textAlign: 'center', marginBottom: '1rem'}}
                                                                dangerouslySetInnerHTML={{__html: data.markdownRemark.html}}>
                                                     </Container>
                                                     {
                                                         !!data.markdownRemark.frontmatter.link &&
                                                         <div className={styles.button}>
                                                             <PrimaryButton link={data.markdownRemark.frontmatter.link}>
                                                                 Mehr erfahren
                                                             </PrimaryButton>
                                                         </div>
                                                     }
                                                 </Segment>
                                             </Container>
                                         </MobileTabletContainer>
                                         <NotebookContainer className={cx(styles.desktop, styles.notebook)}>
                                             <div className={styles.wrapper}>
                                                 <Segment className={styles.container}>
                                                     <Header as={'h1'} textAlign={'center'}
                                                             className={styles.header}>
                                                         {data.markdownRemark.frontmatter.title}
                                                     </Header>
                                                     <Container style={{textAlign: 'center', marginBottom: '1rem'}}
                                                                dangerouslySetInnerHTML={{__html: data.markdownRemark.html}}>
                                                     </Container>
                                                     {
                                                         !!data.markdownRemark.frontmatter.link &&
                                                             <div className={styles.button}>
                                                         <PrimaryButton link={data.markdownRemark.frontmatter.link}>Mehr erfahren</PrimaryButton>
                                                                 </div>
                                                     }
                                                 </Segment>
                                             </div>
                                         </NotebookContainer>
                                         <WideScreenContainer className={cx(styles.desktop, styles.wideScreen)}>
                                             <div className={styles.wrapper}>
                                                 <Segment className={styles.container}>
                                                     <Header as={'h1'} textAlign={'center'}
                                                             className={styles.header}>
                                                         {data.markdownRemark.frontmatter.title}
                                                     </Header>
                                                     <Container style={{textAlign: 'center', marginBottom: '1rem'}}
                                                                dangerouslySetInnerHTML={{__html: data.markdownRemark.html}}>
                                                     </Container>
                                                     {
                                                         !!data.markdownRemark.frontmatter.link &&
                                                         <div className={styles.button}>
                                                             <PrimaryButton link={data.markdownRemark.frontmatter.link}>Mehr erfahren</PrimaryButton>
                                                         </div>
                                                     }
                                                 </Segment>
                                             </div>
                                         </WideScreenContainer>
                                     </>
                                     }
                                 </>
                             )}
                />
        );
    }
}


export default Announcement;

const announcementQuery = graphql`
query announcementQuery {
    markdownRemark(frontmatter: { type: { eq: "announcement" } }) {
        html
        frontmatter {
            title
            link
        }
    }
}
`;