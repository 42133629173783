import React, {Component} from 'react';
import styles from "../templates/default.module.css";
import cx from 'classnames';
import MediaQuery from 'react-responsive';

class NotebookContainer extends Component {
    render() {
        let className = '';

        if (this.props.className !== undefined) {
            className = this.props.className;
        }
        return (
            <div className={cx(className, "desktop")}>
                <MediaQuery minWidth={1024} maxWidth={1439} className={cx(styles.desktop, 'desktop', "notebook")}>
                    {this.props.children}
                </MediaQuery>
            </div>
        );
    }
}

export default NotebookContainer;